<template>
  <div>
    <div class="d-flex justify-content-center col-12 pb-2 text-center">
      <iframe
        data-aa="1767573"
        src="//ad.a-ads.com/1767573?size=970x250"
        :class="
          !is_mobilesize ? 'iframe-desktop_pre pt-1' : 'iframe-mobile_pre'
        "
      ></iframe>
    </div>
    <b-row class="match-height mb-1">
      <b-col lg="12">
        <h1 class="text-center">Featured presales</h1>
      </b-col>
    </b-row>
    <b-row class="match-height mb-1 pt-2" v-if="!is_no_prometed_data">
      <b-col lg="12">
        <h3>Promoted Presales</h3>
      </b-col>
    </b-row>
    <b-row class="match-height mb-1" v-if="!is_no_prometed_data">
      <b-col lg="12">
        <crypto-table
          :table-data="counted_promotedPresaleData"
          :table_name="'presale-promoted'"
          :total="counted_promotedPresaleData.length"
        />
      </b-col>
    </b-row>
    <b-row class="match-height mb-1 pt-2">
      <b-col lg="12">
        <h3>All Presales</h3>
      </b-col>
    </b-row>
    <b-tabs content-class="mt-1">
      <b-tab title="Today's Listing" active>
        <b-row class="match-height mb-1">
          <b-col lg="12">
            <CalenderView
              :presales="presales"
              :today_presales_todal="today_presales_todal"
              :fairlaunch_data="fairlaunch_data"
              :fairlaunch_total="fairlaunch_total"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Upcoming Listings">
        <div class="d-flex col-12">
          <div class="d-flex justify-content-end col-3 text-end">
            <b-form-select
              id="example-weekdays"
              v-model="selected_month"
              :options="months"
              class="mb-2"
              text="name"
              value="number"
              @change="monthSelect"
            ></b-form-select>
          </div>
        </div>
        <b-row class="match-height mb-1">
          <b-col lg="12">
            <Events :upcoming_dates="upcoming_dates" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import { BCol, BRow, BTabs, BTab, BFormSelect } from "bootstrap-vue";
import CryptoTable from "./CryptoTable";
import CalenderView from "../calendar/CalendarView.vue";
import Events from "../calendar/Calendar.vue";

export default {
  mixins: [mixinList],
  data() {
    return {
      today: new Date().toISOString(),
      counted_presaleData: [],
      counted_promotedPresaleData: [],
      is_no_prometed_data: false,
      selected_month: null,
      months: [
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" },
      ],
    };
  },
  components: {
    BCol,
    BRow,
    CryptoTable,
    BTabs,
    BTab,
    BFormSelect,
    CalenderView,
    Events,
  },
  watch: {
    presaleData(val) {
      val.forEach((element) => {
        let newDate = element.release_date;
        if (newDate >= this.today) {
          this.counted_presaleData.push(element);
        }
      });
    },
    promotedPresaleData(val) {
      val.forEach((element) => {
        let newDate = element.release_date;
        if (newDate >= this.today) {
          this.counted_promotedPresaleData.push(element);
        }
        if (this.counted_promotedPresaleData.length < 1) {
          this.is_no_prometed_data = true;
        }
      });
    },
  },
  computed: {
    presaleData() {
      return this.$store.state.crypto.presaleData;
    },
    presale_total() {
      return this.$store.state.crypto.all_total;
    },
    promotedPresaleData() {
      return this.$store.state.crypto.promotedPresaleData;
    },
    promoted_presale_total() {
      return this.$store.state.crypto.all_total;
    },
    upcoming_dates() {
      return this.$store.state.crypto.upcoming_dates;
    },
    fairlaunch_data() {
      return this.$store.state.crypto.fairlaunch_data;
    },
    today_presales_todal() {
      return this.$store.state.crypto.today_presales_todal;
    },
    presales() {
      return this.$store.state.crypto.presales;
    },
    fairlaunch_total() {
      return this.$store.state.crypto.fairlaunch_total;
    },
  },
  mounted() {
    this.fetchPresaleData();
  },
  created() {
    var date = new Date();
    var month = date.getMonth();
    this.selected_month = month + 1;
    this.monthSelect();
  },
  methods: {
    monthSelect() {
      this.$store.dispatch("FETCH_UPCOMING_EVENTS", this.selected_month);
    },
    fetchPresaleData() {
      this.$store.dispatch("FETCH_CLIENT_IP").then(() => {
        this.$store.dispatch("FETCH_PRESALE_DATA", 20);
        this.$store.dispatch("FETCH_PRESALE_PROMOTED_DATA", 20);
      });
    },
  },
};
</script>

<style>
.iframe-desktop_pre {
  width: 970px;
  height: 250px;
  border: 0px;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}
.iframe-mobile_pre {
  width: 350px;
  height: 250px;
  border: 0px;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  /* margin-top: 75px; */
}
</style>
