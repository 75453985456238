<template>
  <div>
    <b-row v-for="(data, index) in upcoming_dates" :key="index">
      <b-col>
        <b-card class="calender-card">
          <b-row>
            <b-col>{{ data.dates }} </b-col>
            <b-col
              ><div class="d-flex justify-content-end col-12 text-end">
                <b-button
                  variant="outline-success"
                  outline
                  size="sm"
                  pill
                  @click="viewEvent(data)"
                  >view</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BButton } from "bootstrap-vue";
// import moment from 'moment';
export default {
  data() {
    return {};
  },
  props: ["upcoming_dates"],
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
  computed: {},
  methods: {
    viewEvent(data) {
      this.$router.push({
        name: "events-view",
        params: { date: data.dates },
      });
    },
  },
  created() {
    if (!this.$store.state.crypto.clientIP) {
      this.$store.dispatch("FETCH_CLIENT_IP").then(() => {
        this.$store.dispatch("FETCH_CRYPTO_DATA", 10);
      });
    }
  },
};
</script>

<style>
.calender-card {
  /* padding: 1px !important; */
  margin-bottom: 0.5rem !important;
}
</style>