<template>
  <div>
    <b-row class="pt-2">
      <b-col>
        <h3>{{ today }} Events</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <app-collapse
          aria-expanded="true"
          class="pt-1"
          aria-controls="collapseOne"
        >
          <app-collapse-item
            visible
            title="Launch"
            class="collapse show"
            v-model="colps"
          >
            <b-alert show variant="success">
              <div class="alert-body">
                <feather-icon class="mr-25" icon="StarIcon" />
                <span class="ml-25">Launch</span>
              </div>
            </b-alert>
            <b-row>
              <b-col>
                <b-card class="collapse-card">
                  <crypto-table
                    :table-data="
                      is_selected_date ? fair_launch_events : fairlaunch_data
                    "
                    :table_name="'fairlaunch_data'"
                    :total="
                      is_selected_date
                        ? fair_launch_events_total
                        : fairlaunch_total
                    "
                  />
                </b-card>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <app-collapse class="pt-1">
          <app-collapse-item title="Presale">
            <b-alert show variant="success">
              <div class="alert-body">
                <feather-icon class="mr-25" icon="StarIcon" />
                <span class="ml-25">Presale</span>
              </div>
            </b-alert>
            <b-row>
              <b-col>
                <b-card class="collapse-card">
                  <crypto-table
                    :table-data="is_selected_date ? presale_events : presales"
                    :table_name="'presales'"
                    :total="
                      is_selected_date
                        ? presale_events_total
                        : today_presales_todal
                    "
                  />
                </b-card>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BAlert } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import CryptoTable from "../../views/crypto/CryptoTable";

export default {
  data() {
    return {
      today: "",
      colps: true,
      is_selected_date: false,
    };
  },
  components: {
    CryptoTable,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BAlert,
    // BButton,
  },
  mounted() {
    if (this.$route.params.date) {
      this.is_selected_date = true;

      this.today = this.$route.params.date;
      this.$store.dispatch("FETCH_PRESALE_EVENTS", this.today);
      this.$store.dispatch("FETCH_FAIR_EVENTS", this.today);
    } else {
      this.today = new Date().toISOString().slice(0, 10);
      this.$store.dispatch("FETCH_TODAY_PRESALE_EVENTS", 20);
      this.$store.dispatch("FETCH_TODAY_FAIR_EVENTS", 20);
    }
  },
  created() {},
  computed: {
    presales() {
      return this.$store.state.crypto.presales;
    },
    today_presales_todal() {
      return this.$store.state.crypto.today_presales_todal;
    },
    fairlaunch_data() {
      return this.$store.state.crypto.fairlaunch_data;
    },
    fairlaunch_total() {
      return this.$store.state.crypto.fairlaunch_total;
    },
    fair_launch_events() {
      return this.$store.state.crypto.fair_launch_events;
    },
    presale_events() {
      return this.$store.state.crypto.presale_events;
    },
    presale_events_total() {
      return this.$store.state.crypto.presale_events_total;
    },
    fair_launch_events_total() {
      return this.$store.state.crypto.fair_launch_events_total;
    },
  },
};
</script>

<style>
.collapse-card {
  background: rgb(11, 12, 12) !important;
}
</style>